import React from "react";
import headerImage from "../assets/paginaimages/Banner contact.jpg";

export default class Contact extends React.Component{
    constructor(props) {
        super(props);
        this.state = { feedback: '', name: '', subject: '', email: '' };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeSubject = this.handleChangeSubject.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    render(){
        return (
            <section className="bg-blue-50">
                <figure className="bg-gray-500 relative">
                    <img className="w-full h-auto lg:h-80 object-cover opacity-50 relative" src={headerImage} alt="Header"/>
                </figure>
                <div className="mx-8 md:mx-24 2md:mx-64 xl:mx-64">
                    <p className="mt-4 text-blue-900">
                        Wilt u meer informatie over de dienstverlening van T & T Beveiligingen, vul dan hieronder het
                        contactformulier in.
                    </p>
                    <p className="mt-4 text-blue-900">U kunt ons uiteraard ook telefonisch bereiken via 010-2327415/
                        06-53536946</p>
                    <p className="mt-4 text-blue-900">Liever direct een e-mail sturen? Mail ons op
                        info@t-tbeveiligingen.nl</p>

                    <h1 className="font-bold text-blue-900 text-2xl sm:text-4xl mt-8">Contact</h1>
                    <p className="mt-4 text-blue-900"> Heeft u naar aanleiding van deze website interesse in één van onze
                        diensten of heeft u behoefte aan meer informatie? Neem dan contact met ons op per email of vul
                        hieronder het contactformulier in. Wij nemen dan zo spoedig mogelijk contact met u op!</p>

                    <form>
                        <div className="flex flex-col mt-4">
                            <label className="text-blue-900" htmlFor="name">Naam</label>
                            <input onChange={this.handleChangeName} value={this.state.name}  className="h-10 focus:shadow-sm px-4 bg-gray-200 rounded-md" type="text" name="name"/>
                        </div>
                        <div className="flex flex-col mt-4">
                            <label className="text-blue-900" htmlFor="email">Uw e-mailadres</label>
                            <input onChange={this.handleChangeEmail} value={this.state.email} className="h-10 focus:shadow-sm px-4 bg-gray-200 rounded-md" type="email" name="email"/>
                        </div>
                        <div className="flex flex-col mt-4">
                            <label className="text-blue-900" htmlFor="subject">Onderwerp</label>
                            <input onChange={this.handleChangeSubject} value={this.state.subject} className="h-10 focus:shadow-sm px-4 bg-gray-200 rounded-md" type="text" name="subject"/>
                        </div>
                        <div className="flex flex-col mt-4">
                            <label className="text-blue-900" htmlFor="message">Uw bericht</label>
                            <input onChange={this.handleChange} value={this.state.feedback} className="h-10 focus:shadow-sm px-4 bg-gray-200 rounded-md" type="text" name="message"/>
                        </div>
                        <input className="mt-6 mb-12 w-32 h-10 bg-blue-900 text-white" type="button" value="Verzenden" onClick={this.handleSubmit}/>
                    </form>
                </div>
            </section>
        );
    }
    handleChange(event) {
        this.setState({feedback: event.target.value})
    }
    handleChangeSubject(event) {
        this.setState({subject: event.target.value})
    }
    handleChangeEmail(event) {
        this.setState({email: event.target.value})
    }
    handleChangeName(event) {
        this.setState({name: event.target.value})
    }

    handleSubmit (event) {
        const templateId = 'template_swdsm8r';

        this.sendFeedback(templateId, {message_html: this.state.feedback, from_name: this.state.name, reply_to: this.state.email, with_subject: this.state.subject})
    }

    sendFeedback (templateId, variables) {
        window.emailjs.send(
            'service_eqkjdoh', templateId,
            variables
        ).then(res => {
            this.setState({name: '', subject: '', email:'', feedback: ''})
            console.log('Email successfully sent!')
        })
            .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
    }
}
