import React, {Component} from "react";
import './index.css'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Modal from '../src/componenten/Modal';

import Contact from './componenten/Contact';
import OverOns from './componenten/OverOns';
import Home from './componenten/Home';
import Camera from './componenten/Camera';
import Toegang from './componenten/Toegang';
import Alarm from './componenten/Alarm';

import mainlogo from './assets/images/T&TLOGO.png';
import chevronIcon from './assets/iconen/chevron.down.svg';
import chevronIconUp from './assets/iconen/chevron.up.svg';

let stateOfModel = false;

class App extends Component {
    constructor() {
        super();
        this.state = {
            show: false, hover: false
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    showModal = () => {
        this.setState({ show: true });
        stateOfModel = true; 
        document.getElementById("chevronIconImg").src="/static/media/chevron.up.f47ce163.svg"; 
        setTimeout(this.detectModal, 1000);
    };

    detectModal = () => {
        console.log(this.state.hover)
        if (!this.state.hover){
            this.hideModal()
        }
    }

    hideModal = () => {
        this.setState({ show: false, hover: false });
        stateOfModel = false; 
        document.getElementById("chevronIconImg").src="/static/media/chevron.down.6aa44cf9.svg"; 
    };

    render() {
        return(
            <Router>
                <Modal show={this.state.show} handleClose={this.hideModal} handleMouseEnter={() => {
                    this.setState({hover: true});
                    console.log(this.state.hover)
                }}  handlemouseLeave={this.hideModal} >

                    <Link to="/alarmsystemen"><p className="mt-1"onClick={this.hideModal}>ALARMSYSTEMEN</p></Link>
                    <Link  to="/camerasystemen"><p className="mt-3"  onClick={this.hideModal}>CAMERASYSTEMEN</p></Link>
                    <Link to="/toegangscontrole"><p className="mt-3 mb-1"onClick={this.hideModal}>TOEGANGSCONTROLE</p></Link>
                </Modal>
                <div className="bg-gray-300">
                    <img className="w-44 mx-auto pt-4" src={mainlogo} alt="Logo"/>
                </div>
                <div className="bg-gray-300">
                    <nav className="mx-auto">
                        <ul className="flex flex-wrap space-x-8 pt-6 pb-2 justify-center">
                            <li>
                                <Link to="/" className="text-gray-800 text-lg">HOME</Link>
                            </li>
                            <li className="hover:cursor-pointer" onMouseEnter={this.showModal} >
                                <div className="flex flex-row">
                                <p onClick={() => {
                                    if(!stateOfModel){
                                        this.showModal()
                                    } else if(stateOfModel){
                                        this.hideModal()
                                    }
                                }} to="/diensten" className="text-gray-800 text-lg cursor-pointer">DIENSTEN</p>
                                <img id="chevronIconImg" src={chevronIcon} alt="Chevron down" className="w-8 h-8 pb-2 mt-1 cursor-pointer"/>
                                </div>
                            </li>
                            <li>
                                <Link to="/overons" className="text-gray-800 text-lg">OVER ONS</Link>
                            </li>
                            <li>
                                <Link to="/contact" className="text-gray-800 text-lg">CONTACT</Link>
                            </li>
                        </ul>
                    </nav>

                    <Switch>
                        <Route path="/overons">
                            <AboutusPage/>
                        </Route>
                        <Route path="/contact">
                            <ContactPage/>
                        </Route>
                        <Route path="/camerasystemen">
                            <Camera/>
                        </Route>
                        <Route path="/alarmsystemen">
                            <Alarm/>
                        </Route>
                        <Route path="/toegangscontrole">
                            <Toegang/>
                        </Route>
                        <Route path="/">
                            <HomePage/>
                        </Route>
                    </Switch>
                </div>
            </Router>
        )
    }
}

function HomePage() {
    return <Home/>;
}

function AboutusPage() {
    return <OverOns/>;
}

function ContactPage() {
    return <Contact/>;
}

export default App;
