import React from "react";

import headerImage from '../assets/images/IMG_171323.jpg';

import icon1 from '../assets/iconen/Camera.png';
import icon2 from '../assets/iconen/inbraak-2.png';
import icon3 from '../assets/iconen/Toegang.png';
import {Link} from "react-router-dom";

import phoneIcon from '../assets/iconen/telefoonIcon.png';
import mailIcon from '../assets/iconen/mailIcon.png';


const home = () => {
    return (
        <section>
            <article>
                <figure className="bg-blue-50">
                    <img className="w-full object-cover opacity-90 h-96" src={headerImage} alt="Header"/>
                </figure>
            </article>
            <article className="bg-white">
                <h1 className="pt-12 text-3xl text-center mx-10 md:mx-28 lg:mx-96 pb-12 leading-8 text-blue-900 font-bold">Welkom
                    op de website van T&T Beveiligingen.</h1>
            </article>
            <article className="bg-blue-50 pb-2">
                <div
                    className="flex flex-col space-x-0 sm:space-x-16 sm:flex-row py-12 justify-center mx-10 md:mx-28 lg:mx-80">
                    <Link to="/alarmsystemen">
                        <div className="mt-8 md:mt-0 hover:underline">
                            <figure>
                                <img className="mx-auto" src={icon2} alt="Camera Icoon"/>
                            </figure>
                            <h1 className="font-bold text-lg md:text-2xl pt-12 text-gray-800 hover:underline text-center">Alarmsystemen</h1>
                        </div>
                    </Link>
                    <Link to="/camerasystemen">
                        <div className="mt-8 md:mt-0 hover:underline">
                            <figure>
                                <img className="mx-auto" src={icon1} alt="Inbraak Icoon"/>
                            </figure>
                            <h1 className="font-bold text-lg md:text-2xl pt-12 text-gray-800 hover:underline text-center">Camerasystemen</h1>
                        </div>
                    </Link>
                    <Link to="/toegangscontrole">
                        <div className="mt-8 md:mt-0 hover:underline">
                            <figure>
                                <img className="mx-auto" src={icon3} alt="Toegang Icoon"/>
                            </figure>
                            <h1 className="font-bold text-lg md:text-2xl pt-12 text-gray-800 hover:underline text-center">Toegangscontrole</h1>
                        </div>
                    </Link>
                </div>
            </article>
            <footer className="bg-white text-center pt-4 md:pt-8 2xl:bottom-0 2xl:absolute 2xl:w-full">
                <div className="flex flex-col md:flex-row">
                    <div className="md:w-1/3 flex flex-row space-x-4 justify-center">
                        <img className="w-12 h-12 sm:mt-14 my-auto" src={phoneIcon} alt="Telefoon icoon"/>
                        <div className="sm:mt-12 my-auto">
                            <p>010-2327415</p>
                            <p className="mt-4">06-53536946</p></div>
                    </div>
                    <div className="md:w-1/3 flex flex-row justify-center space-x-4">
                        <img className="w-12 h-12 sm:mt-14 my-auto" src={mailIcon} alt="Telefoon icoon"/>
                        <div className="sm:mt-16 my-auto">
                            <p className="">info@t-tbeveiligingen.nl</p></div>
                    </div>
                    <div className="md:w-1/3 flex flex-col pt-4 md:pt-0 justify-center space-x-4">
                        <p className="font-semibold text-gray-800 mx-16">T & T Beveiligingen is werkzaam in
                            Zuid-Holland.
                            Plaatsen waar u ons vaak tegenkomt zijn:</p>
                        <p className="text-gray-800">Vlaardingen</p>
                        <p className="text-gray-800">Rotterdam</p>
                        <p className="text-gray-800">Spijkernisse</p>
                        <p className="text-gray-800">Giessenburg</p>
                        <p className="text-gray-800">Rijswijk</p>
                        <p className="text-gray-800">Leiden</p>
                    </div>
                </div>
                <p className="inline-block mx-auto pt-12">Copyright 2021 © T&T beveiligingen</p>
                <br/>
                <p className="inline-block text-gray-500 text-xs hover:underline">
                    <a target="_blank" href="https://irenedekoning.nl">Made with ♥ by iTensio</a>
                </p>
            </footer>
        </section>
    );
}
export default home;