import React from "react";
import {useState, useEffect} from "react";

const query = `
{
  ttBeveiligingenCollection{
    items{
      sectionOne
      alarmsystemenText
      alarmsystemenText2
      alarmsystemenText3
      alarmsystemenText4
      alarmsystemenCollection{
       items{
        url
        url
        url
      }
      }
    }
  }
}
`;


function Alarm() {
    const [page, setPage] = useState(null);
    useEffect(() => {
        window
            .fetch(`https://graphql.contentful.com/content/v1/spaces/ok964buajeme/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer 1CNU2ZypbpH34RBcF1F3WFMjRUaVQQt99MCyeKBrZVc",
                },
                body: JSON.stringify({query}),
            })
            .then((response) => response.json())
            .then(({data, errors}) => {
                if (errors) {
                    console.error(errors);
                }

                setPage(data.ttBeveiligingenCollection.items[0]);
            });
    }, []);

    if (!page) {
        return "Loading...";
    }
    return (
        <section className="bg-blue-50">
            <figure className="bg-gray-400 relative">
                <div className="relative opacity-70 md:h-96 w-full flex flex-col md:flex-row flex-grow">
                    <img alt="Afbeelding Alarm 1" className="md:w-1/3 object-contain pt-8" src={page.alarmsystemenCollection.items[0].url}/>
                    <img alt="Afbeelding Alarm 2" className="md:w-1/3 object-contain p-12" src={page.alarmsystemenCollection.items[2].url}/>
                    <img alt="Afbeelding Alarm 3" className="md:w-1/3 object-contain px-8" src={page.alarmsystemenCollection.items[1].url}/>
                </div>
            </figure>
            <div className="mx-8 md:mx-24 2md:mx-64 xl:mx-64">
                <h1 className="font-bold text-blue-900 text-2xl sm:text-4xl mt-8">{page.sectionOne}</h1>

                <p className="mt-4 text-blue-900">{page.alarmsystemenText}</p>
                <p className="mt-4 text-blue-900">{page.alarmsystemenText2}</p>

                <p className="mt-4 text-blue-900">{page.alarmsystemenText3}</p>
                <p className="mt-4 text-blue-900 pb-6">{page.alarmsystemenText4}</p>
            </div>
        </section>
    );
}

export default Alarm;