import React from "react";
import headerImage from "../assets/images/IMG_1703.jpg";
import {useState, useEffect} from "react";

const query = `
{
  ttBeveiligingenCollection{
    items{
      overonsText
      overonsText2
      overonsText3
      }
  }
}
`;

function OverOns() {
    const [page, setPage] = useState(null);
    useEffect(() => {
        window
            .fetch(`https://graphql.contentful.com/content/v1/spaces/ok964buajeme/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer 1CNU2ZypbpH34RBcF1F3WFMjRUaVQQt99MCyeKBrZVc",
                },
                body: JSON.stringify({query}),
            })
            .then((response) => response.json())
            .then(({data, errors}) => {
                if (errors) {
                    console.error(errors);
                }

                setPage(data.ttBeveiligingenCollection.items[0]);
            });
    }, []);

    if (!page) {
        return "Loading...";
    }
    return (
        <section className="bg-blue-50">
            <figure className="bg-gray-500 relative">
                <img className="w-full h-96 object-cover opacity-90 relative" src={headerImage} alt="Header"/>
            </figure>
            <div className="mx-8 md:mx-24 2md:mx-64 xl:mx-64">
                <h1 className="font-bold text-blue-900 text-2xl sm:text-4xl mt-8">Over Ons</h1>
                <p className="mt-4 text-blue-900">{page.overonsText}</p>
                <p className="mt-4 text-blue-900">{page.overonsText2}</p>
                <p className="mt-4 text-blue-900 pb-6">{page.overonsText3}</p>
            </div>
        </section>
    );
}
export default OverOns;