const Modal = ({ handleClose,  handleMouseEnter, handlemouseLeave, show, children }) => {
    const showHideClassName = show ? "z-10 bg-gray-300 fixed absolute  block left-1/3 -ml-12 lg:ml-24 mt-52 md:mt-44 opacity-100" : "bg-gray-400 fixed hidden";

    return (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handlemouseLeave} className={showHideClassName}>
            <section className=" bg-gray-300 px-4 py-2">
                {children}
            </section>
        </div>
    );
};
export default Modal;